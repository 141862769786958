import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
  states : [],
  cities : [],
  languages: [],
  registrationTypes: [],
  geoLocation: [],
  previousUrl: "",
  websiteSettingsData : [],
  metaTagsData : [],
  subCategories : [],
  groups: [],
};

const commonInputSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCounteries: (state, action) => {
      state.countries = action.payload;
    },
    setLanguages:(state, action) => {
      state.languages = action.payload;
    },
    setStates:(state, action) => {
      state.states = action.payload;
    },
    setCities:(state, action) => {
      state.cities = action.payload;
    },
    setRegistrationTypes: (state, action) => {
      state.registrationTypes = action.payload;
    },
    setGeoLocation: (state, action) => {
      state.geoLocation = action.payload;
    },
    setPreviousUrl: (state, action) => {
      state.previousUrl = action.payload;
    },
    removePreviousUrl: (state) => {
      state.previousUrl = "";
    },
    setWebsiteSettingData : (state, action) => {
      state.websiteSettingsData = action.payload;
    },
    setMetatagsData : (state,action) => {
      state.metaTagsData = action.payload;
    },
    setSubCategories : (state,action) => {
      state.subCategories = action.payload;
    },
    setGroups : (state, action) => {
      state.groups = action.payload;
    }
  },
});

export const {
  setCounteries,
  setStates,
  setCities,
  setLanguages,
  setRegistrationTypes,
  setGeoLocation,
  setPreviousUrl,
  removePreviousUrl,
  setWebsiteSettingData,
  setMetatagsData,
  setSubCategories,
  setGroups
} = commonInputSlice.actions;

export default commonInputSlice.reducer;
