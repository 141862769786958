import { Row, Col, Label, FormGroup } from "reactstrap";
import { Btn, H3, H4, LI, UL } from "../../../../AbstractElements";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import { ErrorMessage, Field, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

const AddDeliveryWorkersForm = (props: any) => {
  const { errors, submitErrors, setSubmitError,values,setFieldValue  } = props;
  const uploadFileHandler = (event: any) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
  };
  const vehicleType = values.vehicleTypes;
  const handleVehicleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Get the value from the select input
    const selectedVehicleType = event.target.value;

    // Set the field value using Formik's setFieldValue
    setFieldValue("vehicleTypes", selectedVehicleType);

    // Optionally handle other logic here, e.g. hiding/showing fields based on selection
    console.log("Selected Vehicle Type:", selectedVehicleType);
  };
  return (
    <Form className="form-wizard">
       <Row className="pb-3">
      <Col  md='7'> 
                                <div className='border rounded border-danger p-2 mt-2'>
                                   
                                    <UL className='list-content '>
                                        <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Left Monthly Balance of Free Submission: 1 `}</LI>
                                        <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Price (30 Days) for additional submission: 3 USD (for 3 additional submissions)  `}</LI>
                                         </UL>
                                </div>
                                </Col>
                            </Row>
      <Row>
        <Col sm="12">
        <H3 className=" fw-semibold mb-2 fs-5 text-gray">Worker's Info</H3>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Delivery Worker's Username <span className="txt-danger">*</span>
          </Label>
          <Field
            as="select"
            name="deliveryWorkerUsername"
            className={`form-control ${
              submitErrors &&
              `${errors.deliveryWorkerUsername ? "is-invalid" : "is-valid"}`
            }`}
          >
            <option value={""}>Please select delivery workers username</option>
            <option value={'1'}>Workers Usersname</option>
          </Field>
          <ErrorMessage
            name="deliveryWorkerUsername"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Confirm User Email <span className="txt-danger">*</span>
          </Label>
          <Field
            name="confirmUserEmail"
            type="text"
            placeholder="Confirm User Email"
            className={`form-control ${
                submitErrors &&
                `${errors.confirmUserEmail ? "is-invalid" : "is-valid"}`
              }`}
          />
          <ErrorMessage
            name="confirmUserEmail"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
          <div className="">
            <Label className="col-form-label m-r-10" check>
              Allowed to Deliver
            </Label>
            <br />
            <div className="d-flex">
              <div className="flex-shrink-0  text-end icon-state switch-outline">
                <CommonSwitchSpan name='allowedToDeliver' color={"primary"} defaultChecked />
              </div>
              <Label className="m-l-10" check></Label>
            </div>
          </div>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Country <span className="txt-danger">*</span>
          </Label>
          <Field as="select" name="country" className={`form-control ${
              submitErrors &&
              `${errors.country ? "is-invalid" : "is-valid"}`
            }`}>
            <option value={""}>Please select country</option>
            <option value='1'>India</option>
          </Field>
          <ErrorMessage
            name="country"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>State</Label>
          <Field as="select" name="state" className="form-control">
            <option value={""}>Please select state</option>
            <option value='1'>Gujarat</option>
          </Field> 
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            City <span className="txt-danger">*</span>
          </Label>
          <Field as="select" name="city" className={`form-control ${
              submitErrors &&
              `${errors.city ? "is-invalid" : "is-valid"}`
            }`}>
            <option value={""}>Please select city</option>
            <option value={'1'}>Surat</option>
          </Field>
          <ErrorMessage
            name="city"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Branch
          </Label>
          <Field as="select" name="branch" className="form-control">
            <option value={""}>Please select branch</option>
          </Field> 
        </Col>
        {vehicleType !== "Bicycle" && vehicleType !== "On Foot"&& ( <Col md='4' className="mb-3">
          <FormGroup>
            <Label check>Driver License Number</Label>
            <Field
            name="vehicleDrivingLicenece"
            type="text"
            placeholder="Enter Driver License Number"
            className={`form-control  `}
          />
          {/* <ErrorMessage
            name="vehicleDrivingLicenece"
            component="span"
            className="invalid-feedback"
          /> */}
          </FormGroup>
        </Col>)}
      </Row>
      
      <Row>
        <Row>
        <Col sm="12">
        <H3 className=" mb-2 fs-5 fw-semibold text-gray">Vehicle Info</H3>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Vehicle’s Type <span className="txt-danger">*</span>
          </Label>
          <Field
          as="select"
          name="vehicleTypes"
          onChange={handleVehicleTypeChange}  // Use the custom handler for onChange
          className={`form-control ${
            submitErrors &&
            `${errors.vehicleTypes ? "is-invalid" : "is-valid"}`
          }`}
        >
          <option value="">Please select Vehicle’s type</option>
          <option value="On Foot">On Foot</option>
          <option value="Bicycle">Bicycle</option>
          <option value="Scooter">Scooter</option>
          <option value="Motor Scooter">Motor Scooter</option>
          <option value="Motorcycle">Motorcycle</option>
          <option value="Three wheeled">Three wheeled</option>
          <option value="Sedan">Sedan</option>
          <option value="SUV">SUV</option>
          <option value="Van">Van</option>
          <option value="Pickup Truck">Pickup Truck</option>
          <option value="Bus">Bus</option>
        </Field>
          <ErrorMessage
            name="vehicleTypes"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        {vehicleType !== "Bicycle" && vehicleType !== "On Foot" && vehicleType !== "Scooter" && vehicleType !== "Motor Scooter" && (
        <Col sm="4" className="mb-3">
          <Label check>
            Vehicle Registration Plate <span className="txt-danger">*</span>
          </Label>
          <Field
            name="vehiclePlate"
            type="text"
            placeholder="Enter Vehicle Registration Plate"
            className={`form-control ${
              submitErrors &&
              `${errors.vehiclePlate ? "is-invalid" : "is-valid"}`
            }`}
          />
          <ErrorMessage
            name="vehiclePlate"
            component="span"
            className="invalid-feedback"
          />
        </Col>
      )}
      {vehicleType !== "On Foot" && vehicleType !== "Bicycle"&& vehicleType !== "Scooter" &&  vehicleType !== "Motor Scooter" &&( <Col sm="4" className="mb-3">
          <Label check>
            Vehicle’s Brand and Module<span className="txt-danger">*</span>
          </Label>
          <Field
            name="vehicleBrand"
            type="text"
            placeholder="Enter Vehicle Brand and Module"
            className={`form-control ${
                submitErrors &&
                `${errors.vehiclePlate ? "is-invalid" : "is-valid"}`
              }`}
          />
          <ErrorMessage
            name="vehicleBrand"
            component="span"
            className="invalid-feedback"
          />
        </Col>)}
       { vehicleType !== "On Foot" &&  <Col sm="4" className="mb-3">
          <Label check>
          Vehicle's Color<span className="txt-danger">*</span>
          </Label>
          <Field
            name="vehicleColor"
            type="text"
            placeholder="Enter Vehicle's Color"
            className={`form-control ${
                submitErrors &&
                `${errors.vehiclePlate ? "is-invalid" : "is-valid"}`
              }`}
          />
          <ErrorMessage
            name="vehicleColor"
            component="span"
            className="invalid-feedback"
          />
        </Col>}
       
        {vehicleType !== "On Foot" &&  <Col md='4' className="mb-3">
          <FormGroup>
            <Label check>Vehicle's Image </Label>
            <span class="txt-danger">*</span>
            <Field
              name="vehicleimage"
              className="form-control"
              type="file"
              onChange={uploadFileHandler}
            /> 
          </FormGroup>
        </Col>}
        
        </Row>
        <Col sm="auto" className="mb-3">
          <Btn color="primary" type="submit" onClick={() => setSubmitError(true)}>Save</Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default AddDeliveryWorkersForm;
