import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LayoutRoutes from "./LayoutRoutes";
import { authRoutes } from "./AuthRoutes";
import Login from "../Component/Authentication/Login";
import SignUp from "../Component/SignUp/SignUp";
import Error400 from "../Pages/OtherPages/Error/Error400/Error400";
import ScrollToTop from "../Component/ScrollToTop.js/ScrollToTop";
import Layout from "../Layout/Layout";
import ForgotPassword from "../Component/Authentication/ForgotPassword";
import ResetPassword from "../Component/Authentication/ResetPassword";
import SecureRoute from "./SecureRoutes";
import { secureAuthRoutes } from "./SecureAuthRoutes";
import TwoFactorConfirmation from "../Component/Authentication/TwoFactorConfirmation";
import EmailVerfication from "../Component/Authentication/EmailVerfication";
import AccountList from "../Pages/Application/AccountList/AccountList"; 
import Tags from "../Component/Meta/Tags";
 

const RouterData = () => {

  return (
    <BrowserRouter basename={"/"}>
      <ScrollToTop />
      <Tags/>
      <Routes>
        <Route
          path={`${process.env.PUBLIC_URL}` || "/"}
          element={
            <Navigate to={`${process.env.PUBLIC_URL}/see-all-contents`} />
          }
        />
        <Route path={`/*`} element={<LayoutRoutes />} />
        <Route path="*" element={<Error400 />} />
        <Route path={"/"} element={<PrivateRoute />}>
          {authRoutes.map(({ path, Component }, i) => (
            <Route element={<Layout />} key={i}>
              <Route path={path} element={Component} key={i} />
            </Route>
          ))}
        </Route>
        <Route path={"/"} element={<SecureRoute />}>
          {secureAuthRoutes.map(({ path, Component }, i) => (
            <Route element={<Layout />} key={i}>
              <Route path={path} element={Component} key={i} />
            </Route>
          ))}
        </Route>
        <Route
          path={`${process.env.PUBLIC_URL}/login/:privateFlag?`}
          element={<Login />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/register-account/:plan_id?/:countryName?/:registrationTypeIds1?`}
          element={<SignUp />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/forgot-password`}
          element={<ForgotPassword />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/password/reset/:token?`}
          element={<ResetPassword />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/2fa`}
          element={<TwoFactorConfirmation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/verify-email`}
          element={<EmailVerfication />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/account-list`}
          element={<AccountList />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
