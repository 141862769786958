import PaymentDetails from "../Pages/Application/Ecommerce/PaymentDetails/PaymentDetails";
import Default from "../Pages/Dashboard/Default/Default";
import TransactionHistory from "../Pages/Application/TransactionHistory/TransactionHistory";
import PaymentMethods from "../Pages/Application/Users/PaymentMethods";
import Withdrawls from "../Pages/Application/Users/Withdrawls";
import MyReferrals from "../Pages/Application/Users/MyReferrals";
import MyResumes from "../Pages/App/AccountSettings/MyResumes";
import ShippingAndDeliveryAddresses from "../Pages/App/AccountSettings/ShippingAndDeliveryAddresses";
import Password from "../Pages/App/AccountSettings/Password";
import MyRegistrationPlan from "../Pages/App/AccountSettings/MyRegistrationPlan";
import EditRegistrationPlan from "../Pages/EditRegistrationPlan/EditRegistrationPlan"; 
import ShippingAddressAddNew from "../Pages/App/AccountSettings/ShippingAddressAddNew";
import ShippingAddressUpdate from "../Pages/App/AccountSettings/ShippingAddressUpdate";
import AccountHistory from "../Pages/App/AccountSettings/AccountHistory";
import Cart from "../Pages/Application/Ecommerce/Cart/Cart";

export const secureAuthRoutes = [
  // dashboard
  {
    path: `${process.env.PUBLIC_URL}/dashboard/user-dashboard`,
    Component: <Default />,
  },
  //User wallet & referrals 
  {
    path: `${process.env.PUBLIC_URL}/dashboard/transactions-history`,
    Component: <TransactionHistory />,
  },
  //user-dashboard
  {
    path: `${process.env.PUBLIC_URL}/dashboard/payment-methods`,
    Component: <PaymentMethods />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/payment-methods/add-new`,
    Component: <PaymentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/withdrawals`,
    Component: <Withdrawls />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/referrals`,
    Component: <MyReferrals />,
  },
  {
    path: `${process.env.PUBLIC_URL}/account-settings/manage-resume`,
    Component: <MyResumes />,
  },
  {
    path: `${process.env.PUBLIC_URL}/account-settings/account-history`,
    Component: <AccountHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cart`,
    Component: <Cart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/account-settings/manage-resume`,
    Component: <MyResumes />,
  },
  {
    path: `${process.env.PUBLIC_URL}/account-settings/password`,
    Component: <Password />,
  },
  {
    path: `${process.env.PUBLIC_URL}/account-settings/my-registration-plan`,
    Component: <MyRegistrationPlan />,
  },
  {
    path: `${process.env.PUBLIC_URL}/registration-plan-edit`,
    Component: <EditRegistrationPlan />,
  }, 
  {
    path: `${process.env.PUBLIC_URL}/account-settings/shipping-addresses`,
    Component: <ShippingAndDeliveryAddresses />,
  },
  {
    path: `${process.env.PUBLIC_URL}/account-settings/shipping-addresses/add-new`,
    Component: <ShippingAddressAddNew />,
  },
  {
    path: `${process.env.PUBLIC_URL}/account-settings/shipping-addresses/update/:id`,
    Component: <ShippingAddressUpdate />,
  }, 
];
