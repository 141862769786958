import { useDispatch } from "react-redux";
import { Btn, SVG } from "../../../../../../AbstractElements";
import { useAppSelector } from "../../../../../../ReduxToolkit/Hooks";
import { Next, Previous, Submit } from "../../../../../../utils/Constant";
import { setNavId, setTabId } from "../../../../../../ReduxToolkit/Reducers/AddProductSlice";

const CommonButton = ({step,bottombutton}) => {
  const {navId,formValue,tabId} = useAppSelector((state) => state.addProduct) 
console.log(navId);

  const dispatch = useDispatch()
  const handleFormValue = () => {
    console.log("tab");
    
    if(formValue.userName !== ""){
      dispatch(setNavId(2))
    }
    if(formValue.fileName !== "" && formValue.fileName1 !== ""){
      dispatch(setNavId(3))
    }
    if(formValue.category !== "" && formValue.status !== ""){
      dispatch(setNavId(4))
    }
    if(formValue.initialCost !== "" && formValue.initialPrice !== "" && formValue.select !== "" && formValue.productStocks !== ""){
      dispatch(setNavId(5))
    }
    if(formValue.stock !== "" && formValue.lowStock !== "" && formValue.sku !== "" && formValue.quantity !== "" && formValue.restock !== "") {
      dispatch(setTabId(2))
    }
    if(formValue.additionalTag !== ""){
      dispatch(setTabId(3))
    }
  }
  const handlePrevious = () => {
    if(navId > 1){
      if(tabId > 1){
        dispatch(setTabId(tabId-1))
      }else{
        dispatch(setNavId(navId-1))
      }
    }
  }
  return (
    <div className={bottombutton ? 'quizz-survey-buttons  pt-0 right-0 position-relative' :' product-buttons' }>
      {navId > 1 &&
        <Btn className="me-2 btn" onClick={()=>handlePrevious()}>
          <div className="d-flex align-items-center gap-sm-2 gap-1">
            <SVG iconId="back-arrow"/>
            {Previous}
          </div>
        </Btn>
      }
      <Btn onClick={()=>handleFormValue()}>
        <div className="d-flex align-items-center gap-sm-2 gap-1">
          {navId === 4 ? Submit : Next}
          <SVG iconId="front-arrow" />
        </div>
      </Btn>
      <Btn onClick={()=>handleFormValue()} className="ms-2 ">
        <div className="d-flex align-items-center  gap-sm-2 gap-1">
          {navId === 4 ? 'Save All Steps' : 'Save as a Draft'}
          <SVG iconId="front-arrow" />
        </div>
      </Btn> 
    </div>
  );
};

export default CommonButton;
