import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import { Field } from "formik";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import GroupMain from "../../../Communication/GroupMain";
import TagsInput from "../../../CommonInput/TagsInput";
import ScheduleType from "../../../CommonInput/ScheduleType";
import { useState } from "react";
import Groups from "../../../CommonInput/Groups";

function Sharing(props: any) {
  const { values, setFieldValue }: any = props;
  const [openFromModal, setOpenFromModal] = useState(false);
  const [reloadGroupsData, setReloadGroupsData] = useState(0);

  const reloadGroups = () => {
    setReloadGroupsData((prev) => prev + 1);
    console.log(reloadGroupsData);
  }

  const openFromModalToggle = () => setOpenFromModal(!openFromModal);
  return (
    <>
      <Row>
        <Col className="mb-3">
          <h4>Sharing</h4>
        </Col>
      </Row>
      <Row>
        <Col sm="auto">
          <FormGroup>
            <div>
              <Label check>{"Display on My Media Center"}:</Label>
            </div>
            <div className="d-flex flex-wrap gap-2 align-items-center">
              <div className="m-checkbox-inline px-2">
                <Label for={`edo-ani-a`} check>
                  <Field
                    className="radio_animated"
                    id={`edo-ani-a`}
                    type="radio"
                    name="display_on_media_center"
                    value={1}
                    checked={values?.display_on_media_center == "1"}
                  />
                  {`To Anyone`}
                </Label>
              </div>
              <div className="m-checkbox-inline px-2 mt-1">
                <Label for={`edo-ani-b`} check>
                  <Field
                    className="radio_animated"
                    id={`edo-ani-b`}
                    type="radio"
                    name="display_on_media_center"
                    value={2}
                    checked={values?.display_on_media_center == "2"}
                  />
                  {`To Followers Only`}
                </Label>
              </div>
              <div className="m-checkbox-inline px-2 mt-1">
                <Label for={`edo-ani-c`} check>
                  <Field
                    className="radio_animated"
                    id={`edo-ani-c`}
                    type="radio"
                    name="display_on_media_center"
                    value={3}
                    checked={values.display_on_media_center == "3"}
                  />
                  {`Do Not Display on My Media Center `}
                </Label>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <FormGroup>
            <FormGroup>
              <Groups
                title="Select Groups"
                className={""}
                isRequired={false}
                name="groups"
                isMulti={true}
                reloadGroupsData={reloadGroupsData}
              />
            </FormGroup>
            <span>Create a New Group</span>
            <span
              className="mt-1 btn btn-primary px-3 mx-2"
              onClick={openFromModalToggle}
            >
              <i className="icofont icofont-users"></i>
            </span>
            <CommonModal
              isOpen={openFromModal}
              toggle={openFromModalToggle}
              sizeTitle="Add a Group"
              size="lg"
            >
              <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                <GroupMain reloadGroups={reloadGroups} openFromModalToggle={openFromModalToggle} />
              </div>
            </CommonModal>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <FormGroup>
            <div className=" col-form-Label">
              <FormGroup className=" ">
                <Label check className="mb-1">
                  Usernames :
                </Label>
                <TagsInput
                  name="individual_users"
                  username={true}
                  placeholder="Enter Usernames"
                  className={`form-control pt-0`}
                  setFieldValue={setFieldValue}
                />
              </FormGroup>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default Sharing;