import {useState} from 'react'
import { Card, CardBody,CardHeader,Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import {BranchesContactColumnData, BranchesContactWhatsAppColumnData, BranchesEmailColumnData, supportTicketData } from '../../../../../Data/Tools/BrandSupportTools/ButtonActions'
import {BranchsAction } from '../../../../../Data/Tools/ManageContents/ManageContentButtons'
import { ContactNoList, EmailList, Href, WhatsappNoList } from '../../../../../utils/Constant'
import SubMenu from '../../../../../Component/Application/SubMenu/SubMenu'
import BranchSubMenu from '../BranchSubMenu/BranchSubMenu'
import ManageOnlineSubMenu from '../../ManageOnlineSubMenu/ManageOnlineSubMenu'
import { Formik } from 'formik'
import Message from '../../../../../Component/CommonMessage/Message'
import { branchPublicContactFormInitialValue, BranchPublicContactFormValidationProp, branchPublicContactFormValidationSchema } from '../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import AddBranchPublicContactForm from '../../../../../Component/Tools/ManageOnlineStore/ManageBranches/BranchPublicContact/AddBranchPublicContactForm'

const BranchPublicContact = () => {
    const [basicTab, setBasicTab] = useState<string>("1");
    const toggleDelete = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const handleRowSelected =() =>{}
    const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (values: BranchPublicContactFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
  };
    return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`New Branche public Contact (Manage Online Store)`} parent={`Manage Store`} />
      <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
      <Container fluid> 
        <Row>
          <Col sm="12">
          <div className='ms-1 mt-2'>
          <BranchSubMenu/>

                  </div> 
            <Card > 
              <CardBody className='p-2'>
                <>
                  <Card className='shadow-none'>
                  <div   className="nav-warning "> 
                  
                    <NavItem>
                      <NavLink
                        href={Href}
                        className={basicTab === "2" ? "bg-gray text-white d-inline-block rounded" : "d-inline-block"}
                        onClick={() => setBasicTab("2")}
                      >
                        {ContactNoList}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={Href}
                        className={basicTab === "3" ? "bg-gray text-white d-inline-block rounded" : "d-inline-block"}
                        onClick={() => setBasicTab("3")}
                      >
                        {EmailList}
                      </NavLink>
                    </NavItem>
                    <NavItem> 
                      <NavLink
                        href={Href}
                        className={basicTab === "1" ? "bg-gray text-white d-inline-block rounded" : "d-inline-block"}
                        onClick={() => setBasicTab("1")}
                      >
                        Add Branch Contacts
                      </NavLink>
                    </NavItem>
                    

                    {/* <NavItem className='w-50'>
                      <Link to={`${process.env.PUBLIC_URL}/manage-online-store/manage-branches/branch-public-contacts/add-new`}><Btn className='bg-primary float-end'>{`Add a Branch Contact`}</Btn></Link>
                    </NavItem> */}
                  </div>

                  </Card>
                  <TabContent activeTab={basicTab}>
                     
                    <TabPane tabId={`2`} >
                      <div className="table-responsive">
                        <DataTable className='custom-scrollbar' columns={BranchesContactColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                      </div>
                    </TabPane>
                    <TabPane tabId={`3`} >
                      <div className="table-responsive">
                        <DataTable className='custom-scrollbar' columns={BranchesEmailColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                      </div>
                    </TabPane>
                    <TabPane tabId={`1`} >
                      <div className="table-responsive">

                      <Card className="p-4">
        <Message message="Please add at least one branch first to be able to use this form."/>
        <Formik
          initialValues={branchPublicContactFormInitialValue}
          onSubmit={handleSubmit}
          validationSchema={branchPublicContactFormValidationSchema}
        >
          {({ errors, values, setFieldValue }) => (
            <AddBranchPublicContactForm
              submitErrors={submitErrors}
              setSubmitError={setSubmitError}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
        </Formik>
        
        </Card>
                      </div>
                    </TabPane>
                  </TabContent>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default BranchPublicContact