import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import {
  Education,
  HobbiesAndInterest,
  JobCancelButton,
  JobDesiredHeading,
  JobSubmitButton,
  Languages,
  Objective,
  Skill,
  SkillNExperience,
  Training,
  UploadRecommendationsJob,
} from "../../../../utils/Constant";
import { ErrorMessage, Form, Field, FieldArray } from "formik";
import { Btn, H3 } from "../../../../AbstractElements";
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput";
import { useEffect, useState } from "react";
import Language from "../../../CommonInput/Language";
import SubCategory from "../../../CommonInput/SubCategory";
import { Link } from "react-router-dom";
import Loading from "../../../Loading/Loading";

const GeneralInformation = (props: any) => {
  const {
    errors,
    values,
    submitErrors,
    setSubmitError,
    setFieldValue,
    resumeData,
  } = props;
  const [newFormCount, setNewFormCount] = useState(["1"]);

  const addFormHandler = (arrayHelpers: any) => {
    const value = newFormCount.length + 1;
    arrayHelpers.push({
      addOnOption: "",
      addOnExtraPrice: "",
    });
    setNewFormCount((oldArray) => [...oldArray, value.toString()]);
  };

  const removeFormHandler = (
    value: string,
    arrayHelpers: any,
    index: number
  ) => {
    const updatedFormCount = newFormCount.filter((item) => item !== value);
    setNewFormCount(updatedFormCount);
    arrayHelpers.remove(index);
    values.work_history && values.work_history.splice(index);
  };

  useEffect(() => {
    if (resumeData) {
      const arrCount: any = [];
      setFieldValue("id", resumeData.id);
      setFieldValue(
        "display_resume_on_media_ceneter",
        resumeData.display_resume_on_media_ceneter
      );
      setFieldValue("subcategories", resumeData.subcategories[0].id);
      setFieldValue(
        "job_title",
        resumeData.job_title && resumeData.job_title.toString()
      );
      setFieldValue("objective", resumeData.objective);
      setFieldValue("summery", resumeData.summery);
      setFieldValue("education", resumeData.education);
      setFieldValue("training", resumeData.training);
      setFieldValue("languages", resumeData.languages[0].id);
      setFieldValue("skills", resumeData.skills);
      setFieldValue("hobbies_interests", resumeData.hobbies_interests);
      setFieldValue("recommendation", resumeData.recommendation);
      resumeData.work_history.map((item: any, index: number) => {
        setFieldValue(`work_history.${index}.name`, item.name);
        setFieldValue(`work_history.${index}.description`, item.description);
        arrCount.push(index);
      });
      setNewFormCount(arrCount);
    }
  }, [resumeData]);

  // when click on the image file input.
  const uploadFileHandler = (event: any, setFieldValue: any) => {
    setFieldValue("uploadImage", event.target.files[0]);
  };

  if(resumeData === undefined) return <Loading/>; 

  return (
    <Form className="">
      <Row>
        <Col xl="4" md="4">
          <Label check>
            Display Resume on My Media Center:
            <span className="font-danger">*</span>
          </Label>
          <div className="m-checkbox-inline px-2">
            <Label for={`type-a`} check>
              <Field
                className="radio_animated"
                id={`type-a`}
                type="radio"
                name="display_resume_on_media_ceneter"
                value="1"
                checked={values.display_resume_on_media_ceneter === "1"}
              />
              {`Display the Full Resume `}
            </Label>
            <Label for={`type-b`} check>
              <Field
                className="radio_animated"
                id={`type-b`}
                type="radio"
                name="display_resume_on_media_ceneter"
                value="2"
                checked={values.display_resume_on_media_ceneter === "2"}
              />
              {`Only the Biography Summary`}
            </Label>
          </div>
        </Col>

        <Col xl="4" md="4" className=" my-2">
          <SubCategory
            name="subcategories"
            className={`${
              submitErrors &&
              `${errors.subcategory ? "is-invalid" : "is-valid"}`
            }`}
          /> 
        </Col>
        <Col xl="4" md="4">
          <Label check>
            {JobDesiredHeading}:<span className="font-danger">*</span>
          </Label>

          <TagsInput
            name="job_title"
            tags={values?.job_title && values?.job_title.split(',')}
            placeholder={JobDesiredHeading}
            setFieldValue={setFieldValue}
            className={`form-control ${
              submitErrors && `${errors.job_title ? "is-invalid" : "is-valid"}`
            }`}
          />
          <ErrorMessage
            name="job_title"
            component="span"
            className="invalid-feedback"
          />
        </Col>

        <Col xl="4" md="4">
          <Label check>{Objective}:</Label>
          <Field
            as="textarea"
            placeholder={Objective}
            name="objective"
            className={`form-control ${
              submitErrors && `${errors.objective ? "is-invalid" : "is-valid"}`
            }`}
            autoFocus={true}
          />
          <ErrorMessage
            name="objective"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col md="4" className=" ">
          <FormGroup>
            <Label className="w-100" check>
              {`Biography Summery`} :
            </Label>
            <Field
              as="textarea"
              name="summery"
              className="form-control"
              maxLength={"300"}
              placeholder="Description/Summary"
            />
            <TextCounter length={values?.summery?.length} totalLength="300" />
          </FormGroup>
        </Col>
        <Col md="12" className="mt-3 mb-4">
          <H3 className="mb-0">{"Work History:"}</H3>
        </Col>
        <FieldArray
          name="addons"
          render={(arrayHelpers) => {
            return (
              <>
                {newFormCount.map((item, index) => (
                  <Container
                    fluid
                    className="p-5 mb-4"
                    id={`prize-container-${item}`}
                    key={index}
                    style={{ border: "1px solid #ccc" }}
                  >
                    <Row>
                      <Col md={10} className="mb-3">
                        <h4>Experience {index + 1}</h4>
                      </Col>
                      <Col md={2} className="mb-3">
                        {newFormCount.length !== 1 && (
                          <span
                            className="btn btn-outline-danger"
                            onClick={() =>
                              removeFormHandler(item, arrayHelpers, index)
                            }
                          >
                            <i className="icofont icofont-ui-delete"></i>
                          </span>
                        )}
                        {newFormCount.length === index + 1 && (
                          <span
                            className="btn btn-outline-dark ms-2"
                            onClick={() => addFormHandler(arrayHelpers)}
                          >
                            <i className="fa fa-plus"></i>
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" className="mb-3">
                        <Label check>
                          Experience {index + 1} Name{" "}
                          <span className="txt-danger">*</span>
                        </Label>
                        <Field
                          name={`work_history.${index}.name`}
                          type="text"
                          placeholder="Experience Name"
                          maxLength="140"
                          className={`form-control ${
                            errors.work_history?.[index]?.name && submitErrors
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                        />
                        <TextCounter
                          length={
                            values?.work_history?.[index]?.name?.length || 0
                          }
                          totalLength="140"
                        />
                        <ErrorMessage
                          name={`work_history.${index}.name`}
                          component="span"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col sm="6" className="mb-3">
                        <Label check>
                          Work History and Companies
                          <span className="txt-danger">*</span>
                        </Label>
                        <Field
                          name={`work_history.${index}.description`}
                          as="textarea"
                          placeholder="Work History Description"
                          className={`form-control ${
                            errors.work_history?.[index]?.description &&
                            submitErrors
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                        />
                        <ErrorMessage
                          name={`work_history.${index}.description`}
                          component="span"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                  </Container>
                ))}
              </>
            );
          }}
        />
        <Col md="12" className="mt-3 mb-4">
          <H3 className="mb-0">{SkillNExperience}</H3>
        </Col>
        <Col xl="6" md="6" className="my-2">
          <Label check>{Education}:</Label>
          <Field
            type="text"
            placeholder={Education}
            name="education"
            className={`form-control`}
            autoFocus={true}
          />
        </Col>
        <Col xl="6" md="6" className="my-2">
          <Label check>{Training}:</Label>
          <Field
            type="text"
            placeholder={Training}
            name="training"
            className={`form-control`}
            autoFocus={true}
          />
        </Col>
        <Col xl="6" md="6">
          <Label>{Languages}</Label>
          <Language name="languages" isMulti={false} className={``} />
        </Col>
        <Col xl="6" md="6">
          <Label check>{"Other Skills"}:</Label>
          <Field
            type="text"
            placeholder={Skill}
            name="skills"
            className={`form-control`}
            autoFocus={true}
          />
        </Col>
        <Col xl="6" md="6" className="my-2">
          <Label check>{HobbiesAndInterest}:</Label>
          <Field
            type="text"
            placeholder={HobbiesAndInterest}
            name="hobbies_interests"
            className={`form-control`}
            autoFocus={true}
          />
        </Col>

        <Col xl="6" md="6" className="my-2">
          <Label className="col-form-label pt-0" check>
            {UploadRecommendationsJob}:
          </Label>
          <Input
            type="file"
            name="uploadImage"
            multiple
            onChange={(event: any) => uploadFileHandler(event, setFieldValue)}
          />
          {values.recommendation && (
            <a href={values.recommendation} download target="_blank" className="my-2">
              Download File
            </a>
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Btn
            color="primary mx-1"
            type="submit"
            onClick={() => setSubmitError(true)}
          >
            {JobSubmitButton}
          </Btn>
          <Btn color="light" type="button">
            {JobCancelButton}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default GeneralInformation;
