import { CardHeader, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import SelectOne from "./SelectOne";
import SelectTwo from "./SelectTwo";
import CreateNewCategory from "./CreateNewCategory";
import SelectFour from "./SelectFour";
import SelectFive from "./SelectFive";
import TagsInput from "../../../../../CommonInput/TagsInput";
import { ErrorMessage, Field, Formik } from "formik";
import TextCounter from "../../../../../CommonInput/TextCounter";
import {
    createPostInitialValue,
    createPostSchema,
} from "../../../../../../Data/Post/CreatePostValidationSchema";
import { useState } from "react";
import { ProductTitle } from "../../../../../../utils/Constant";
import { useAppSelector } from "../../../../../../ReduxToolkit/Hooks";
import { useDispatch } from "react-redux";
import FormEditors from "./FormEditors";
import { setFormValue } from "../../../../../../ReduxToolkit/Reducers/AddProductSlice";
import { H3, H4, LI, UL } from "../../../../../../AbstractElements";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductThree = () => {
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const { formValue } = useAppSelector((state) => state.addProduct);
    const dispatch = useDispatch();
    const handleSubmit = () => {};

    return (
        <>
            <CardHeader className="ps-0 pt-2">
                <H3>{'General Info (step 1)'}</H3>
              </CardHeader>
              <Row className="pb-3">
      <Col  md='7'>
                                <H4>{`Notes:`}</H4>
                                <div className='border rounded border-danger p-2 mt-2'>
                                   
                                    <UL className='list-content '>
                                        <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Left Monthly Balance of Free Submission: 1 `}</LI>
                                        <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Price (30 Days) for additional submission: 3 USD (for 3 additional submissions)  `}</LI>
                                         </UL>
                                </div>
                                </Col>
                            </Row>
        <div className="sidebar-body mt-4">
        <Formik
                                initialValues={createPostInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={createPostSchema}>
                                {({ errors, values, setFieldValue }) => (
                                    <>
                                        <Form>
        <FormGroup>
                <Row className="g-lg-4 g-3">
                    <Row> 
        <Col sm="12" className="mb-3">
          <Label check>A Previous Product or Service Contest as a Reference (Optional){" "}</Label>
          <Field as="select" className="form-control" name="reference-coupon">
            <option value={""}>Select a Reference</option>
          </Field>
        </Col>
                        <Col sm="4" md="6">
                            <FormGroup>
                                <Label check>{`Category *`}:</Label>
                                <FormGroup>
                                    <Input type="select">
                                        <option>{`Select`}</option>
                                        <option>
                                            Free for Commercial and Non-profit
                                            use
                                        </option>
                                        <option>
                                            Free for Non-Profit use only
                                        </option>
                                         
                                    </Input> 
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col sm="4" md="6">
                            <Formik
                                initialValues={createPostInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={createPostSchema}>
                                {({ errors, values, setFieldValue }) => (
                                    <>
                                        <Form>
                                            <FormGroup>
                                                <Label check>
                                                    {`Name of a Product  or Service`}{" "}
                                                    <span className="txt-danger">
                                                        {" "}
                                                        *
                                                    </span>
                                                    :
                                                </Label>
                                                <Field
                                                    type="text"
                                                    name="subject"
                                                    placeholder={`Name of a Product or Service`}
                                                    className={`form-control ${
                                                        submitErrors &&
                                                        `${
                                                            errors.subject
                                                                ? "is-invalid"
                                                                : "is-valid"
                                                        }`
                                                    }`}
                                                />
                                                <TextCounter
                                                    length={
                                                        values?.subject?.length
                                                    }
                                                    totalLength="140"
                                                />
                                                <ErrorMessage
                                                    name="subject"
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4" md="6">
                            <Row className="g-2">
                                <Col xs="12">
                                    <Label className="m-0">
                                        {"Inventory Number"}{" "}
                                        <span className="txt-danger"> *</span>
                                    </Label>
                                </Col>
                                <Col xs="12" className="custom-input">
                                    <Input
                                        className={
                                            formValue.userName !== ""
                                                ? "valid"
                                                : "is-invalid"
                                        }
                                        type="text"
                                        required
                                        name="F"
                                        onChange={(e) =>
                                            dispatch(
                                                setFormValue({
                                                    name: "userName",
                                                    value: e.target.value,
                                                })
                                            )
                                        }
                                    />
                                </Col>
                                {/* <FormEditors /> */}
                            </Row>
                        </Col>
                        <Col sm="4" md="6">
                            <Formik
                                initialValues={createPostInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={createPostSchema}>
                                {({ errors, values, setFieldValue }) => (
                                    <>
                                        <Label check>Keywords (optional)</Label>
                                        <TagsInput
                                            name="keywords"
                                            placeholder="Add keywords"
                                            setFieldValue={setFieldValue}
                                        />
                                    </>
                                )}
                            </Formik>
                        </Col>
                    </Row> 
                </Row>
            </FormGroup>
            </Form>
            </>
            )}
            </Formik> 
        </div>
        </>
    );
};

export default ProductThree;
